import { useCallback, useState } from "react";

export const useToggle = (initialValue?: boolean) => {
  const [value, setValue] = useState(initialValue ?? false);
  const toggle = useCallback(() => setValue(v => !v), []);
  return {
    value,
    setValue,
    toggle,
  };
};
