import React from "react";
import styled from "styled-components";
import { BasicLayout } from "../layouts/BasicLayout";
import { AppText, AppTitle, NavBar } from "../components/ui";
import { Accordion } from "../components/ui/Accordion";
import theme from "../util/theme";
import { Link } from "gatsby";
import { mobileSize } from "../util/variables";

export default () => {
  return (
    <BasicLayout>
      <Main>
        <NavBar initiallyWhite />
        <Content>
          <AppTitle textAlign="center" margin="0 0 36px">
            Frequently Asked Questions
          </AppTitle>
          <Accordion title="What is Imprint?">
            <AccordionText>
              Imprint is a completely new way to learn, with interactive, visual lessons on
              essential topics. Millions of people use Imprint to learn important insights in
              psychology, philosophy, finance, leadership, business, health, science, technology,
              and more.
            </AccordionText>
            <AccordionText noMargin>
              Imprint lessons use our unique visual, interactive format that can help you stay
              engaged, understand complex concepts quickly, and remember what you learn.{" "}
              <StyledLink to="/welcome">To try it, start a 7-day free trial.</StyledLink>
            </AccordionText>
          </Accordion>

          <Accordion title="Is Imprint worth it?">
            <AccordionText>
              If you’re considering an Imprint subscription, take it from some 25,000+ 5 star
              reviews we’ve gotten on the App Store and Google Play store:
            </AccordionText>
            <AccordionText>
              <em>
                “Imprint is completely life changing. As a person that always loves reading but
                doesn’t have much time, this app is perfect for me.”
              </em>
            </AccordionText>
            <AccordionText>
              <em>
                “Imprint helps me stay engaged. I retain more information than I would if I read on
                my own. I would recommend it to anyone trying to pick up better habits and learn
                more.”
              </em>
            </AccordionText>
            <AccordionText>
              <em>
                “This app is a must-have for the avid reader and learner… The visuals really do
                wonders!”
              </em>
            </AccordionText>
            <AccordionText noMargin>
              If you’re still not convinced,{" "}
              <StyledLink to="/welcome">
                we recommend a free 7 day trial to see for yourself.
              </StyledLink>
            </AccordionText>
          </Accordion>

          <Accordion title="What is included in my Imprint subscription?">
            <AccordionText noMargin>
              With a paid Imprint subscription, you get full access to Imprint&apos;s entire library
              of courses, quick reads, and guides to bestselling books.
            </AccordionText>
          </Accordion>

          <Accordion title="Is Imprint free?">
            <AccordionText noMargin>
              Imprint offers a <StyledLink to="/welcome">free 7 day trial</StyledLink> with easy
              cancelation.
            </AccordionText>
          </Accordion>

          <Accordion title="How does the 7-day free trial work?">
            <AccordionText noMargin>
              If you sign up for a 7-day free trial, you will have access to the full Imprint
              library. If you cancel at any time during the trial, you won&apos;t be charged and
              will lose access after the trial ends. If you don&apos;t cancel, you&apos;ll be
              charged for an auto-renewing annual subscription after 7 days.
            </AccordionText>
          </Accordion>

          <Accordion title="How do I cancel my Imprint subscription?">
            <AccordionText>
              Canceling your subscription is easy and should always take less than 2 minutes but
              instructions will vary depending on where you purchased your subscription.
            </AccordionText>
            <AccordionText noMargin>
              <em>If you purchased through the Imprint website,</em> follow these instructions:
            </AccordionText>
            <ul>
              <li>
                <AccordionText noMargin>
                  Log in to{" "}
                  <StyledLink
                    to="/"
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    imprintapp.com
                  </StyledLink>{" "}
                  using the{" "}
                  <StyledLink
                    to="/user/login"
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    Log in
                  </StyledLink>{" "}
                  link in the header.
                </AccordionText>
              </li>
              <li>
                <AccordionText noMargin>
                  Click &apos;Manage&apos; near your subscription details.
                </AccordionText>
              </li>
              <li>
                <AccordionText noMargin>Select &apos;Cancel Plan&apos;</AccordionText>
              </li>
              <li>
                <AccordionText noMargin>
                  Click once more to confirm to Cancel your plan.
                </AccordionText>
              </li>
            </ul>
            <AccordionText>
              <em>If you purchased through the App Store,</em>{" "}
              <StyledLink
                to="https://support.apple.com/en-us/HT202039"
                target="_blank"
                rel="noopenner noreferrer"
              >
                follow these cancelation instructions.
              </StyledLink>
            </AccordionText>
            <AccordionText>
              <em>If you purchased through Google Play,</em>{" "}
              <StyledLink
                to="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid"
                target="_blank"
                rel="noopenner noreferrer"
              >
                follow these cancelation instructions.
              </StyledLink>
            </AccordionText>
            <AccordionText noMargin>
              If you have any trouble, please reach out to our support team at{" "}
              <StyledA href="mailto:info@imprintapp.com">info@imprintapp.com.</StyledA>
            </AccordionText>
          </Accordion>

          <Accordion title="There’s a problem with my subscription—what do I do?">
            <AccordionText noMargin>
              Please reach out to our support team at{" "}
              <StyledA href="mailto:info@imprintapp.com">info@imprintapp.com.</StyledA>
            </AccordionText>
          </Accordion>
        </Content>
      </Main>
    </BasicLayout>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 80px 24px;
  padding-top: calc(140px + 80px); // navbar height + padding
  background-color: ${theme.GREY_COLOR};

  @media ${mobileSize} {
    padding-top: calc(55px + 40px); // navbar height + padding
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 800px;
  width: 100%;

  @media ${mobileSize} {
    gap: 16px;
  }
`;

const AccordionText = styled(AppText as any).attrs({
  color: "#605B66",
  fontSize: 20,
  mobileFontSize: 16,
})``;

const StyledLink = styled(Link)`
  color: ${theme.PRIMARY_COLOR};
  text-decoration: underline;
`;

const StyledA = styled.a`
  color: ${theme.PRIMARY_COLOR};
  text-decoration: underline;
`;
