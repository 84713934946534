import React from "react";
import { useToggle } from "../../hooks/useToggle";
import styled from "styled-components";
import theme from "../../util/theme";
import { AppText } from "./AppText";
import { mobileSize } from "../../util/variables";
import { AppIcon } from "./AppIcon";

interface AccordianProps {
  title: string;
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordianProps> = ({ title, children }) => {
  const { value: isOpen, toggle } = useToggle();
  return (
    <Container open={isOpen}>
      <Header onClick={toggle}>
        <AppText noMargin fontSize={24} mobileFontSize={20} fontWeight={500}>
          {title}
        </AppText>
        <ChevronWrapper open={isOpen}>
          <AppIcon
            icon="chevron-right"
            size={32}
            color={isOpen ? theme.WHITE_COLOR : theme.PRIMARY_COLOR}
            style={{ transition: "color 0.2s ease-in-out" }}
          />
        </ChevronWrapper>
      </Header>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </Container>
  );
};

const Container = styled.div<{ open: boolean }>`
  width: 100%;
  border: 2px solid ${props => (props.open ? theme.PRIMARY_COLOR : theme.WHITE_COLOR)};
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 2px 8px 0px rgba(164, 164, 164, 0.25);
  transition: all 0.25s ease-in-out;

  @media ${mobileSize} {
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  cursor: pointer;

  @media ${mobileSize} {
    padding: 16px 24px;
  }
`;

const ChevronWrapper = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  background-color: ${props => (props.open ? theme.PRIMARY_COLOR : theme.WHITE_COLOR)};
  border-radius: 50%;
  box-shadow: 0px 2px 8px 0px rgba(164, 164, 164, 0.25);
  transform: ${props => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 0.2s ease-in-out;

  @media ${mobileSize} {
    height: 24px;
    width: 24px;
  }
`;

const AccordionContent = styled.div`
  padding: 0 32px 32px 32px;

  @media ${mobileSize} {
    padding: 0 24px 16px 24px;
  }
`;
